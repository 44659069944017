<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-row>
            <v-col
                    cols="2"
                    v-for="(item, index) in modules"
                    v-bind:key="index"
                    class="viewCard"
            >
                <router-link :to="item.link">
                    <HoverCard :cardData="item"></HoverCard>
                </router-link>
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "Dashboard",
        watch: {},
        props: [
            'moduleName'
        ],
        components: {
            HoverCard
        },
        computed: {
            modules() {
                return [
                    {
                        name: this.$t('airport_list'),
                        type: "monthlyYearlyReport",
                        link: "/reportModules/reportAirport/airportList",
                        icon: "mdi-chart-areaspline"
                    },
                    {
                        name: this.$t('domestic_air_movement'),
                        type: "reportHeaderSetting",
                        link: "/reportModules/reportAirport/reportDomesticMovement",
                        icon: "mdi-chart-areaspline"

                    },
                    ]
            },
            items(){
            return [
                {
                    text: this.$t('reports'),
                    exact: true,
                    to: '/reportModules'
                },
                {
                    text: this.$t('airport_report'),
                    disabled: true,
                    exact: true
                }
                    ]
    }
            },
        mounted() {
        },
        methods: {}
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>